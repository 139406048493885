<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">动物接收</h3>
    <div class="detail">
      <h4 class="title">实验动物基本情况</h4>
      <div class="desc">
        <span>动物品系：{{ activeInfo.strain }}</span>
        <span>清洁等级：{{
            activeInfo.grade == 0 ? '普通' : activeInfo.grade == 1 ? 'SPF' : '清洁级'
          }}</span>
        <p>
          <span>雌性周龄：{{ female.weekDays }}周</span>
          <span>雌性体重：{{ female.weight }}g</span>
          <span>雌性数量：{{ female.count }}</span>
        </p>
        <p>
          <span>雄性周龄：{{ male.weekDays }}周</span>
          <span>雄性体重：{{ male.weight }}g</span>
          <span>雄性数量：{{ male.count }}</span>
        </p>
      </div>
      <el-form ref="addForm" size="small" :model="addExper" class="addForm" label-suffix=":"
               label-width="86px">
        <el-form-item label="外包装" prop="oouter">
          <el-input v-model="addExper.outer" :readonly="readonly" placeholder="请输入外包装信息"></el-input>
        </el-form-item>
        <h3 class="tit">供货单位情况</h3>
        <div class="desc">
          <p>
            <span>单位名称：{{ activeInfo.aniSupply.supplier }}</span>
            <span>许可证：{{ activeInfo.aniSupply.prodCert }}</span>
            <span>合格证号：{{ activeInfo.aniSupply.certNo }}</span>
          </p>
          <p>
            <span>运输工具：{{ activeInfo.aniSupply.transport }}</span>
            <span>单位负责人：{{ activeInfo.aniSupply.supplyPrincipal }}</span>
            <span>联系电话：{{ activeInfo.aniSupply.supplyPhone }}</span>
          </p>
        </div>
        <h3 class="tit">实验动物验收结果</h3>
        <div class="desc">不合格动物：</div>
        <el-table
            :data="addExper.aniOrderDetailList"
            border
            class="tableMember"
            :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
            max-height="500">
          <el-table-column
              width="80"
              label="性别">
            <template slot-scope="scope">
              {{ scope.row.gender == 0 ? '♀' : '♂' }}
            </template>
          </el-table-column>
          <el-table-column
              prop="belowRecWeight" width="100"
              label="体重(g)" show-overflow-tooltip>
          </el-table-column>
          <el-table-column
              prop="weekDays"
              label="周龄" width="80">
          </el-table-column>
          <el-table-column label="数量">
            <template slot-scope="scope">
              <el-input size="small" type="number" :max="scope.row.count" :min="0"
                        placeholder="数量" :readonly="readonly"
                        v-model="scope.row.belowRecNum"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="处理方法">
            <template slot-scope="scope">
              <el-input size="small" placeholder="处理方法" :readonly="readonly"
                        v-model="scope.row.belowRecHandle"></el-input>
            </template>
          </el-table-column>
        </el-table>
        <div class="fromSave">
          <template v-if="receptState!=1">
            <el-button type="primary" size="small" :disabled="disabled" @click="submitBtn('addForm',1)">确认接收</el-button>
            <el-button plain type="primary" size="small" :disabled="disabled" @click="submitBtn('addForm',0)">保存
            </el-button>
          </template>
          <el-button plain type="primary" size="small" @click="exportInfo()">导出预览</el-button>
        </div>
      </el-form>
    </div>
  </div>

</template>

<script>

export default {
  name: "receive",
  props: ["id", 'status'],
  data() {
    return {
      activeInfo: {
        aniSupply: {}
      },
      addExper: {
        outer: "",
        aniOrderDetailList: []
      },
      receptState: null,
      disabled: false,
      readonly: false,
      // 雌性
      female: {},
      // 雄性
      male: {},
    }
  },
  mounted() {
    if (this.status > 8) {
      this.getReceive()
    } else {
      this.getType()
    }
  },
  methods: {
    getType() {
      let that = this;
      that.$get("/subject/order/to/" + this.id).then(res => {
        if (res.status == 200) {
          that.activeInfo = res.data

          // 动物基本信息
          res.data.aniOrderDetailList.forEach(obj => {
            if (obj.gender == 0) {
              this.female = obj
            } else {
              this.male = obj
            }
            this.addExper.aniOrderDetailList.push({
              subId: obj.subId,
              id: obj.id,
              gender: obj.gender,
              belowRecWeight: obj.weight,
              weekDays: obj.weekDays,
              belowRecNum: null,
              count: obj.count,
              belowRecHandle: "",
            })
          })
        }
      }).catch(() => {
        this.$message.error('获取数据失败')
      })
    },
    getReceive() {
      this.$get("/subject/receive/" + this.id).then(res => {
        if (res.status == 200) {

          this.receptState = res.data.aniOrder.aniRecept.state

          if (this.receptState == 1) {
            this.readonly = true
            this.disabled = true
          }

          this.activeInfo = res.data.aniOrder
          // 外包装信息
          this.addExper.outer = res.data.aniOrder.aniRecept.oouter

          res.data.aniOrder.aniOrderDetailList.forEach(obj => {
            if (obj.gender == 0) {
              this.female = obj
            } else {
              this.male = obj
            }
            this.addExper.aniOrderDetailList.push({
              subId: obj.subId,
              id: obj.id,
              gender: obj.gender,
              belowRecWeight: obj.weight,
              weekDays: obj.weekDays,
              belowRecNum: obj.belowRecNum,
              count: obj.realNum,
              belowRecHandle: obj.belowRecHandle,
            })
          })
        }

      }).catch(() => {
        this.$message.error('获取数据失败')
      })
    },
    submitBtn(task, state) {
      this.$refs[task].validate((valid) => {
        if (valid) {
          this.addExper.subId = this.id
          this.addExper.state = state
          this.$putJson('/subject/order', this.addExper).then(res => {
            this.$message.success("接收成功")
            setTimeout(this.$router.go(-1), 1200)
          }).catch(err => {
            this.$message.error("接收失败")
          })
        } else {
          return false;
        }
      })
    },
    exportInfo() {
      this.$emit('changeStatus', false, 0)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
